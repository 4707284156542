/*
 * @Date: 2023-02-14 14:04:12
 * @LastEditors: simLarry
 * @LastEditTime: 2023-03-08 21:46:23
 * @FilePath: \F1-M2-QRP-CODE\pages\hayhar.js
 */

import React from 'react';
import { fetchRequest } from '$CONFIG/Fatch.config';

import Header from '@/hayhar/header'; // 头部 组件
import Central from '@/hayhar/central'; // 表单 组件
import Footer from '@/hayhar/footer'; // 底部内容
import Socialbtn from '@/hayhar/socialbtn'; // 社交按钮
import Modal from '@/modal'; //

import { ApiPort } from '$ACTIONS/API';
import HostConfig from '$CONFIG/Host.config'
import Router from 'next/router';
import { checkAffQueryString, getApiVersion } from '../actions/util';
import Toast from '../components/Toast';
export const CMSURL = HostConfig.Config.CMSURL;



export default class hayHar extends React.Component {
    constructor() {
        super();
        this.state = {
            bannerList: [],
            pagename: '',

            mainSiteUrl : '',
            liveChatUrl : '',

            isModal:false,
            error_text : '',
        }

        this.closeModal = this.closeModal.bind(this);
        this.openModal = this.openModal.bind(this);
    }

    componentDidMount() {
        this.init();
    }

    init() {
        // 获取URL 参数
        checkAffQueryString(global.location.search);
        // 获取banner
        this.getBanner();
        // 获取当前page
        this.getPageName();

        this.QrpLoad();
    }

    /**
     * 获取当前路径
     */
    getPageName() {
        console.log(Router.asPath);
        if(Router.asPath.includes('=')) return ;
        this.setState({
            pagename: Router.asPath.split('/')[1] || '',
        })
    }

    getBanner() {
        fetchRequest(ApiPort.Banner1, 'GET').then(res => {
            let bannerList = [{
                cmsImageUrlWeb: '/static/images/hayhar/QRP-NUFC-Desktop.jpg',
                cmsImageUrlMobile: '/static/images/hayhar/QRP-NUFC-Mobile.jpg'
            }];
            try {
                if (res) {
                    if (res.length > 0) {
                        bannerList = res.map((val, index) => {
                            return { cmsImageUrlWeb: CMSURL + val.cmsImageUrlWeb, cmsImageUrlMobile: CMSURL + val.cmsImageUrlMobile }
                        })

                    }
                }
            } catch (error) {

            }
            this.setState({bannerList})
        })
    }



    QrpLoad() {
        fetchRequest(ApiPort.QrpLoad, 'GET', '', getApiVersion()).then(res => {
            if (res) {
                if (res.isSuccess){
                    this.setState({
                        liveChatUrl : res.liveChatUrl,
                        mainSiteUrl : res.mainSiteUrl,
                        nativeAppDownloadUrl : res.nativeAppDownloadUrl
                    })
                }
            }
        })
    }


    closeModal(){
        this.setState({
            isModal : false
        })
    }


    openModal(text){
        this.setState({
            error_text : text,
            isModal : !this.state.isModal
        })
    }

    render() {
        let { bannerList, pagename , mainSiteUrl , liveChatUrl} = this.state;

        return (
            <div className={`hayhar-page ${pagename}`}>
                {/* 顶部 */}
                <Header openModal={this.openModal} mainSiteUrl={mainSiteUrl}></Header>
                {/* 表单 */}
                <Central openModal={this.openModal} bannerList={bannerList}></Central>
                {/* 底部 */}
                <Footer mainSiteUrl={mainSiteUrl}></Footer>
                {/* 社交按钮 */}
                <Socialbtn liveChatUrl={liveChatUrl}></Socialbtn>

                {/* 弹窗提示 */}
                <Modal error_text={this.state.error_text} closeModal={this.closeModal} isModal={this.state.isModal}></Modal>
            </div>
        )
    }
}
